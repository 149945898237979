/* eslint-disable global-require */
// eslint-disable-next-line no-unused-vars
import store from '@/store'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, watchEffect,
} from '@vue/composition-api'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function announements() {
  const toast = useToast()
  const searchQuery = ref('')

  const announcementsList = ref([
    {
      id: 1,
      img: require('@/assets/images/slider/02.jpg'),
      title: 'The Best Features Coming to iOS and Web design',
      avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      userFullName: 'Ghani Pradita',
      blogPosted: 'Jan 10, 2020',
      tags: ['Tag', 'Seconde'],
      excerpt: 'Donut fruitcake soufflé apple pie candy canes jujubes croissant chocolate bar ice cream.',
      comment: 76,
    },
    {
      id: 2,
      img: require('@/assets/images/slider/06.jpg'),
      title: 'Latest Quirky Opening Sentence or Paragraph',
      avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      userFullName: 'Jorge Griffin',
      blogPosted: 'Jan 10, 2020',
      tags: ['Third', 'Seconde'],
      excerpt: 'Apple pie caramels lemon drops halvah liquorice carrot cake. Tiramisu brownie lemon drops.',
      comment: 2100,
    },
    {
      id: 3,
      img: require('@/assets/images/slider/04.jpg'),
      title: 'Share an Amazing and Shocking Fact or Statistic',
      avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
      userFullName: 'Claudia Neal',
      blogPosted: 'Jan 10, 2020',
      tags: ['Third', 'First'],
      excerpt: 'Tiramisu jelly-o chupa chups tootsie roll donut wafer marshmallow cheesecake topping.',
      comment: 243,
    },
    {
      id: 4,
      img: require('@/assets/images/slider/03.jpg'),
      title: 'Withhold a Compelling Piece of Information',
      avatar: require('@/assets/images/portrait/small/avatar-s-14.jpg'),
      userFullName: 'Fred Boone',
      blogPosted: 'Jan 10, 2020',
      tags: ['Seconde'],
      excerpt: 'Croissant apple pie lollipop gingerbread. Cookie jujubes chocolate cake icing cheesecake.',
      comment: 10,
    },
    {
      id: 5,
      img: require('@/assets/images/slider/09.jpg'),
      title: 'Unadvertised Bonus Opening: Share a Tag',
      avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      userFullName: 'Billy French',
      blogPosted: 'Jan 10, 2020',
      tags: ['Tag', 'Seconde'],
      excerpt: 'Muffin liquorice candy soufflé bear claw apple pie icing halvah. Pie marshmallow jelly.',
      comment: 319,
    },
    {
      id: 6,
      img: require('@/assets/images/slider/10.jpg'),
      title: 'Ships at a distance have Every Man’s Wish on Board',
      avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      userFullName: 'Helena Hunt',
      blogPosted: 'Jan 10, 2020',
      tags: ['First', 'Tag'],
      excerpt: 'A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.',
      comment: 1500,
    },
    {
      id: 7,
      img: require('@/assets/images/slider/10.jpg'),
      title: 'Ships at a distance have Every Man’s Wish on Board',
      avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      userFullName: 'Helena Hunt',
      blogPosted: 'Jan 10, 2020',
      tags: ['First', 'Tag'],
      excerpt: 'A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.',
      comment: 1500,
    },
    {
      id: 8,
      img: require('@/assets/images/slider/10.jpg'),
      title: 'Ships at a distance have Every Man’s Wish on Board',
      avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      userFullName: 'Helena Hunt',
      blogPosted: 'Jan 10, 2020',
      tags: ['First', 'Tag'],
      excerpt: 'A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.',
      comment: 1500,
    },
  ])
  const selectedEntityId = ref(null)

  const selectedFile = ref(null)
  const data = new FormData()

  const addAnnounce = ref({
    activity_name: null,
    activity_description: null,
    media_file: data.append('file', selectedFile.value),
    enable_status: null,
    enable_likes: null,
    enable_comments: null,
    enable_share: null,
    creator_id: 1,
    entity_id: 1,
    creator_type: 'ngo',
    activity_type: 'announcement',
    frequency: 0,
    activity_order: 0,
    activity_approval: 0,
  })

  const resolveGenStatusValue = status => {
    if (status === 1) return true
    if (status === 0) return false
    return 'primary'
  }

  const fetchD = () => store.dispatch('announceData/showAnnounce', { id: router.currentRoute.params.id })
  fetchD()

  const showData = computed(() => store.state.announceData.announceShow)

  const fetchAnnounce = () => store.dispatch('announceData/getAnnouncements')
  fetchAnnounce()

  const fetAnn = computed(() => store.state.announceData.announements)

  const onFileSelected = event => {
    // eslint-disable-next-line prefer-destructuring
    selectedFile.value = event.target.files[0]
  }

  const addAnnounceData = () => {
    store.dispatch('announceData/announceAdd', addAnnounce.value).then(res => {
      if (res.data.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push('/announcecments')
      // eslint-disable-next-line no-empty
      } else {}
    }).catch()
  }

  const filteredData = watchEffect(() => store.dispatch('announceData/filterByEntityId', { id: selectedEntityId.value }))

  const entityData = computed(() => store.state.announceData.entityId)

  const updateData = ref({
    creator_id: 1,
    entity_id: 2,
    creator_type: 'ngo',
    activity_type: 'announcement',
    frequency: 0,
    activity_order: 0,
    activity_approval: 0,
  })

  return {
    announcementsList,
    showData,
    fetAnn,
    addAnnounce,
    addAnnounceData,
    selectedFile,
    onFileSelected,
    data,
    resolveGenStatusValue,
    updateData,
    searchQuery,
    selectedEntityId,
    entityData,
    filteredData,
  }
}
