import axios from 'axios'

export default {
  namespaced: true,
  state: {
    announements: [],
    announceShow: [],
    searchedData: [],
    entityId: null,
  },
  getters: {},
  mutations: {
    GET_DATA(state, data) {
      state.announements = data
    },
    GET_SHOW_DATA(state, data) {
      state.announceShow = data
    },
    GET_SEARCH(state, data) {
      state.searchedData = data
    },
    GET_ENTITY_ID(state, data) {
      state.entityId = data
    },
  },
  actions: {
    getAnnouncements({ commit }) {
      axios.get('https://activities.muslimdo.com/api/internalops/activities/announcement/1').then(res => {
        commit('GET_DATA', res.data.data.data)
      })
    },
    showAnnounce({ commit }, { id }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/activities/show/${id}/announcement`).then(res => {
        commit('GET_SHOW_DATA', res.data.data.activity)
      }).catch(err => console.log(err))
    },
    announceAdd(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('https://activities.muslimdo.com/api/internalops/activities', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateAnnounce({ ctx }, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://activities.muslimdo.com/api/internalops/activities/${id}?_method=PUT`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    filterByEntityId({ commit }, { id }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/filter/announcement/entity_id/${id}`).then(res => {
        commit('GET_ENTITY_ID', res.data.data)
      }).catch(err => console.log(err))
    },
  },
}
