<template>
  <div>
    <b-card>
      <div>
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-group
              label="Classification"
              label-for="classification"
            >
              <b-form-select
                v-model="selectedEntityId"
                :options="entityId"
                class="mb-3"
                value-field="id"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select Entity --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="getSearch"
              />
              <b-button
                :to="{name: 'add-announce'}"
                variant="primary"
              >
                <span class="text-nowrap">Add Announcement</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row>
      <!-- draggable -->
      <b-col
        v-if="paginatedItems.length === 0"
        cols="12"
      >
        <b-card class="text-centerr">
          <div class="text-center">
            <h5>Ther Is No Announcements! <br> <br> Please Select Entity</h5>
          </div>
        </b-card>
      </b-col>
      <draggable
        v-model="paginatedItems"
        group="people"
        class="list-group list-group-flush cursor-move"
        v-bind="dragOptions"
        @start="dragging = true"
        @end="dragging = false"
      >
        <b-col
          v-for="announce in paginatedItems"
          id="my-table"
          ref="table"
          :key="announce.id"
          md="4"
        >
          <b-card
            tag="article"
            no-body
          >
            <b-link>
              <b-img
                :src="require('@/assets/images/slider/02.jpg')"
                class="card-img-top"
              />
            </b-link>
            <b-card-body>
              <b-card-title>
                <b-link
                  class="blog-title-truncate text-body-heading"
                >
                  {{ announce.activity_name }}
                </b-link>
              </b-card-title>
              <b-card-text class="announce-content-truncate">
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="announce.activity_description.slice(0, 150)" />
              </b-card-text>
              <hr>
              <div class="d-flex justify-content-between align-items-center">
                <b-link>
                  <div class="d-flex align-items-center text-body">
                    <feather-icon
                      icon="MessageSquareIcon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">10 Comments</span>
                  </div>
                </b-link>
                <template>
                  <b-dropdown
                    variant="link"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{name: 'view-announce', params: { id: announce.id }}"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50"> View </span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :to="{name: 'edit-announce', params: { id: announce.id }}"
                    >
                      <feather-icon icon="EditIcon" />
                      <span> Edit </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteAnnunce(announce.id)">
                      <feather-icon icon="TrashIcon" />
                      <span
                        class="align-middle ml-50"
                      >Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </draggable>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          first-number
          last-number
          class="mb-0 mt-1"
          prev-class="prev-item"
          next-class="next-item"
          @input="onPageChanged"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable vue/no-unused-components */
import {
  BAvatar, BRow, BCol, BCard, BCardText, BCardTitle, BMedia, BTable, BFormSelect, BFormSelectOption, BFormGroup,
  BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BPagination, BDropdown, BDropdownItem, BButton, BFormInput,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { onUnmounted } from '@vue/composition-api'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import announcement from '../announcements'
import announcementDataModule from '../announementsModule'

Vue.use(VueSweetalert2)

export default {
  display: 'Transitions',
  components: {
    BAvatar,
    BFormSelect,
    BFormSelectOption,
    BTable,
    BRow,
    BCol,
    draggable,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BImg,
    BButton,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      items: null,
      paginatedItems: [],
      totalRows: null,
      currentPage: 1,
      perPage: 3,
      searchQuery: '',
      drag: [],
      tableColumns: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'description', sortable: true },
      ],
      entityId: [
        {
          id: 1,
          name: 'MuslimDo',
        },
        {
          id: 2,
          name: 'MuslimDo-Test',
        },
      ],
      entity: null,
    }
  },
  setup() {
    const ANNOUNCE_MODULE = 'announceData'
    // Register module
    if (!store.hasModule(ANNOUNCE_MODULE)) store.registerModule(ANNOUNCE_MODULE, announcementDataModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ANNOUNCE_MODULE)) store.unregisterModule(ANNOUNCE_MODULE)
    })

    const {
      fetchData, announcements, selectedEntityId, entityData,
    } = announcement()

    return {
      fetchData,
      announcements,
      selectedEntityId,
      entityData,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  watch: {
    // paginatedItems: {
    //   handler() {
    //     localStorage.setItem('announcements', JSON.stringify(this.paginatedItems))
    //   },
    //   deep: true,
    // },
    entityData(oldval) {
      this.entity = oldval
      this.paginatedItems = oldval.slice(0, this.perPage)
      this.items = oldval
      this.totalRows = oldval.length
      console.log(oldval.length)
    },
  },
  mounted() {
    // this.getBlogs()
    this.paginate(this.perPage, 0)
    this.getSearch()
  },
  created() {
    console.log(this.totalRows)
    console.log(this.entity)
  },
  methods: {
    kFormatter,
    // myProvider() {
    //   this.loading = true
    //   const promis = axios.get('https://activities.muslimdo.com/api/internalops/activities/announcement/1')
    //   return promis.then(res => {
    //     console.log(res.data.data.total)
    //     this.paginatedItems = res.data.data.data
    //     this.items = res.data.data.data
    //     this.totalRows = res.data.data.total
    //     const items = res.data.data.data
    //     return items
    //   }).finally(() => {
    //     this.loading = false
    //   // eslint-disable-next-line no-unused-vars
    //   }).catch(error => [])
    // },
    deleteAnnunce(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00A5AF',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`https://activities.muslimdo.com/api/internalops/activities/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'Anouncement has been deleted.',
              'success',
            )
            this.$router.go()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    // eslint-disable-next-line camelcase
    paginate(page_size, page_number) {
      const itemsToParse = this.items
      this.paginatedItems = itemsToParse.slice(
        // eslint-disable-next-line camelcase
        page_number * page_size,
        // eslint-disable-next-line camelcase
        (page_number + 1) * page_size,
      )
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1)
    },
    // getBlogs() {
    //   if (localStorage.getItem('announcements')) {
    //     this.paginatedItems = JSON.parse(localStorage.getItem('announcements'))
    //   }
    // },
    getSearch() {
      axios.get(`https://activities.muslimdo.com/api/internalops/search/announcement/${this.searchQuery}`).then(res => {
        console.log(res.data.data)
        if (this.searchQuery.length > 3) {
          this.paginatedItems = res.data.data
        }
        if (this.searchQuery.length <= 0) {
          this.paginatedItems = this.entityData
        }
        this.totalRows = res.data.data.length
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
.list-group {
    display: contents;
    /* flex-direction: column; */
}
</style>
